@import 'variables';

// ******************************************
// Dark Select Button modifying PrimeNG
// ******************************************

.chronos-select-button-dark.p-selectbutton .p-button {
  font-size: $font-size-base;
  font-weight: $font-weight-regular;
  border-color: $gray-4;
  background: $gray-3;

  &.p-highlight {
    background: $blue-8;
    border-color: $gray-10;
    color: $gray-1;
  }

  &:focus {
    box-shadow: none;
  }
}

// ******************************************
// Blue Select Button modifying PrimeNG
// ******************************************

.chronos-select-button-blue.p-selectbutton .p-button {
  font-size: $font-size-md;
  font-weight: $font-weight-bold;

  &:focus {
    box-shadow: none;
  }
}

// ******************************************
// Split Button modifying PrimeNG
// ******************************************

$split-button-size: 36px;
$split-button-font-size: $font-size-base;
$split-button-font-weight: $font-weight-medium;
$split-button-border-radius: 5px;
$split-button-menu-padding: 7.5px 15px;
$split-button-disabled-color: $gray-5;

.chronos-split-button {
  width: 100%;

  & .p-button {
    font-size: $split-button-font-size;
    font-weight: $split-button-font-weight;
    font-family: $roboto-family;
    border-radius: $split-button-border-radius;
    height: $split-button-size;

    &:focus {
      box-shadow: none;
    }

    &:disabled {
      background: $split-button-disabled-color;
      border-color: $split-button-disabled-color;
      opacity: 0.65;
    }
  }

  & .p-button.p-button-icon-only {
    width: $split-button-size;
  }

  & .p-splitbutton-menubutton {
    border-left: none;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  &.p-splitbutton .p-splitbutton-defaultbutton {
    border-right: none;
    margin-right: 2px;
  }

  &--toggle {
    & .p-button {
      border-radius: $split-button-border-radius;
    }

    &.p-splitbutton .p-splitbutton-defaultbutton {
      display: none;
    }
  }

  &-menu {
    font-size: $split-button-font-size;
    font-weight: $font-weight-regular;
    font-family: $roboto-family;

    &.p-menu.p-menu-overlay {
      padding: $default-padding 0;
      border-radius: $split-button-border-radius;
      box-shadow: none;
    }

    &.p-menu .p-menuitem-link .p-menuitem-text {
      color: inherit;
    }

    &.p-menu .p-menuitem-link {
      transition: none;
      padding: $split-button-menu-padding;
      color: $primary-5;

      &:focus {
        background: $primary-1;
        box-shadow: none;
      }

      &:not(.p-disabled):hover {
        background: $primary-1;

        & .p-menuitem-text {
          color: inherit;
        }
      }
    }
  }

  &-single {
    font-size: $split-button-font-size;
    font-weight: $split-button-font-weight;
    font-family: $roboto-family;
    width: 100%;

    &.p-button {
      height: $split-button-size;
      border-radius: $split-button-border-radius;

      &:focus {
        box-shadow: none;
      }

      &:disabled {
        background: $split-button-disabled-color;
        border-color: $split-button-disabled-color;
        opacity: 0.65;
      }
    }
  }
}

// ******************************************
// Input Switch from PrimeNG
// ******************************************

.p-inputswitch {
  .p-inputswitch-slider {
    background: $gray-1;
    border: 1px solid $gray-6;

    &:before {
      border: 1px solid $gray-6;
      left: 0.2em;
      bottom: 0.2em;
    }
  }

  &.p-inputswitch-checked {
    .p-inputswitch-slider {
      background: $success-4;

      &:before {
        background: $gray-1;
        border: none;
        left: 0.2em;
        bottom: 0.2em;
      }
    }

    &:not(.p-disabled):hover .p-inputswitch-slider {
      background: $success-4;
    }
  }

  &:not(.p-disabled):hover .p-inputswitch-slider {
    background-color: $gray-1;
    border: 1px solid $gray-6;
  }

  &.p-focus &-slider {
    box-shadow: none;
  }
}

.chronos-file-updload .p-button {
  &.p-highlight {
    background: $blue-8;
    border-color: $gray-10;
    color: $gray-1;
  }

  &:focus {
    box-shadow: none;
  }
}

.chronos-file-updload .p-fileupload-buttonbar {
  border-bottom: 0 none;
  background: #ffffff;
  padding: 0;
  color: #ffffff;
  border: 0px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}

.chronos-file-updload .p-fileupload-content {
  background: #dadada;
  padding: 0;
  border: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.chronos-file-updload .p-progressbar {
  border: 0 none;
  height: 0px;
  background: #ffffff;
}

.chronos-file-updload .p-fileupload .p-fileupload-buttonbar .p-button {
  margin-right: 25.5rem;
}

.chronos-file-updload .p-button.p-fileupload-choose {
  margin-bottom: 10px;
}
