//************************** UI COLORS **************************//

//*** Grey Palette ***//
$gray-1: #ffffff;
$gray-2: #f5f5f5;
$gray-3: #e6e6e6;
$gray-4: #d0d0d0;
$gray-5: #b9b9b9;
$gray-6: #a2a2a2;
$gray-7: #8b8b8b;
$gray-8: #707070;
$gray-9: #5e5e5e;
$gray-10: #454545;
$gray-11: #2e2e2e;
$gray-12: #000000;

//*** Blue Palette ***//
$blue-1: #e3e9ef;
$blue-2: #c8d4df;
$blue-3: #d6dfe7;
$blue-4: #bbc9d7;
$blue-5: #7794af;
$blue-6: #5f768c;
$blue-7: #2f3b46;
$blue-8: #475869;
$blue-9: #171d23;

//*** Primary ***//
$primary-1: #d9eaf9;
$primary-2: #b3d6f4;
$primary-3: #8cc1ee;
$primary-4: #0077db;
$primary-5: #00569c;
$primary-6: #00325c;

//*** Error ***//
$error-1: #f6d9d9;
$error-2: #eeb3b3;
$error-3: #e58c8c;
$error-4: #c70000;
$error-5: #950000;
$error-6: #630000;

//*** Warning ***//
$warning-1: #fbe7d9;
$warning-2: #f8cfb3;
$warning-3: #f5b68c;
$warning-4: #fe8833;
$warning-5: #b74a00;
$warning-6: #833500;

//*** Attention ***//
$attention-1: #fef8e4;
$attention-2: #fdf2ca;
$attention-3: #fdecaf;
$attention-4: #fbd54f;
$attention-5: #bc9f3b;
$attention-6: #7d6a27;

//*** Success ***//
$success-1: #e5ecd9;
$success-2: #ccd9b3;
$success-3: #b1c58c;
$success-4: #548000;
$success-5: #3e5f00;
$success-6: #293f00;

//************************** ACCENT COLORS **************************//

//*** mayr-melnhof ***//
$mayr-melnhof-1: #d9e9e3;
$mayr-melnhof-2: #b3d3c7;
$mayr-melnhof-3: #8cbdab;
$mayr-melnhof-4: #006e45;
$mayr-melnhof-5: #005233;
$mayr-melnhof-6: #003622;

//*** magenta ***//
$magenta-1: #f5d9e6;
$magenta-2: #ecb3cd;
$magenta-3: #e38cb3;
$magenta-4: #c20058;
$magenta-5: #910041;
$magenta-6: #60002b;

//*** gold ***//
$gold-1: #fcf1d9;
$gold-2: #fae3b3;
$gold-3: #f8d58c;
$gold-4: #f0a200;
$gold-5: #b37900;
$gold-6: #775000;

// Theme colors
//************************** CUSTOM COLORS **************************//

$contrast-text-color: white;
$text-color: black;
$default-header-footer-color: white;
$default-background-color: $gray-2;
$default-input-border: $gray-6;
$default-input-focus: $primary-4;
$default-kpi-color: $gray-3;
$default-panel-color: $gray-4;
$contrast-background-color: black;
$light-background-color: white;
$link: $primary-5;
$error-row-color: $error-3;
$attention-row-color: $attention-2;
$success-row-color: $success-1;
$kpi-column-color: $gray-3;

/*** Mounting pallet colors ***/
$pallet-color: white;
$dummy-pallet-color: $warning-3;
$dummy-pallet-drag-color: #dca37e;

/*** Consumption pallet colors ***/
$consumption-actions-bg-color: $gray-2;
$consumption-actions-color: $blue-9;

/*** Flags ***/
$flag-next-bgr: $primary-4;

/*** Main menu ***/
$main-menu-link: white;
$main-menu-active: $blue-7;
$main-menu-hover: $primary-4;
$main-menu-badge-bgr: $gold-4;
$main-menu-badge-color: black;

/*** Buttons ***/
$info-button-color: $primary-5;
$error-button-color: $error-5;
$error-button-secondary-color: $error-4;
$primary-button: $primary-4;
$primary-button-border: $primary-5;
$success-button-color: $success-4;
$warning-button-color: $warning-4;
$default-button-color: $gray-7;
$primary-button-color: white;
$primary-button-hover: $primary-5;
$primary-button-text-color: white;

/*** Icons ***/
$icon-success: $success-4;
$icon-neutral: $blue-3;
$icon-warning: $warning-5;
$icon-error: $error-4;
$icon-default: black;

/*** Badges ***/
$badge-neutral: $success-4;
$badge-warning: $gold-4;
$badge-danger: $error-4;
