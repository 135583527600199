/* You can add global styles to this file, and also import other style files */

@import 'assets/scss/variables';
@import 'assets/scss/roboto';
@import 'assets/bootstrap/bootstrap-grid.min.css';
@import 'assets/scss/global';
@import 'assets/scss/form';
@import 'assets/scss/table';
@import 'assets/scss/tabs';
@import 'assets/icons/style.css';
@import 'assets/scss/icons';
@import 'assets/scss/mixins';
@import 'assets/scss/buttons';
@import 'assets/scss/qa-check';
@import 'assets/scss/mounting-container-list';
@import 'assets/scss/status-flag';
@import 'assets/scss/dropdown';
@import 'assets/scss/inputs';
@import 'assets/scss/dialogs';
@import 'assets/scss/calendar';

.no-scroll-dialog {
  .p-dialog-content {
    overflow: hidden;
  }
}

.time-out-modal {
  .question {
    text-align: left !important
  }
}

.p-overflow-hidden {
    overflow: visible;
}
